import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  'recommendations.added_to_basket': {
    'en-GB': 'Added to Basket',
    'nl-NL': 'Toegevoegd aan winkelmandje',
  },
  'recommendations.error_modal.continue': {
    'en-GB': 'Let’s Go',
    'nl-NL': 'Aan de slag',
  },
  'recommendations.error_modal.text': {
    'en-GB': 'Oops, something went wrong…please try again.',
    'nl-NL': 'Oeps, er ging iets mis... probeer het opnieuw.',
  },
  'recommendations.error_modal.heading': {
    'en-GB': 'It’s Not You, it’s Us!',
    'nl-NL': 'Het ligt niet aan jou, maar aan ons!',
  },
  'recommendations.error_modal.label': {
    'en-GB': 'Something went wrong, please close the modal and try again!',
    'nl-NL': 'Er ging iets mis, sluit het venster en probeer het opnieuw',
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})
