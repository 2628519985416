import { gql } from '@moonpig/web-core-graphql'

export const PRODUCT_FIELDS_FRAGMENT = gql`
  fragment ProductVariantFields on ProductVariant {
    key
    title
    subtitle
    inStock
    sku
    minimumQuantity
    price {
      centAmount
      currencyCode
      fractionDigits
    }
    fullPrice {
      centAmount
      currencyCode
      fractionDigits
    }
    discountedPercentage
    bundles {
      description
      price {
        currencyCode
        centAmount
        fractionDigits
      }
      discountPercentage
      size
    }
    dimensions {
      description
    }
    capabilities {
      video
    }
  }

  fragment ProductFields on Product {
    id
    title
    description
    slug
    dependencies
    customisable
    editableTextFieldCount
    photoUploadCount
    isLandscape
    category {
      id
      slug
      name
      department
    }
    rating {
      count
      score
    }
    masterVariant {
      ...ProductVariantFields
      images {
        url
      }
      masterImage {
        url
      }
    }
    variants {
      ...ProductVariantFields
    }
    hasAugmentedReality
    productPills {
      displayLabel
      displayVariant
    }
    primaryProductPill {
      displayLabel
      displayVariant
    }
    isSponsored
    clickRankDocumentCount
  }
`
export const PRODUCT_FIELDS_FRAGMENT_WITHOUT_CLICK_RANK = gql`
  fragment ProductVariantFields on ProductVariant {
    key
    title
    subtitle
    inStock
    sku
    minimumQuantity
    price {
      centAmount
      currencyCode
      fractionDigits
    }
    fullPrice {
      centAmount
      currencyCode
      fractionDigits
    }
    discountedPercentage
    bundles {
      description
      price {
        currencyCode
        centAmount
        fractionDigits
      }
      discountPercentage
      size
    }
    dimensions {
      description
    }
    capabilities {
      video
    }
  }

  fragment ProductFieldsWithoutClickRank on Product {
    id
    title
    description
    slug
    dependencies
    customisable
    editableTextFieldCount
    photoUploadCount
    isLandscape
    category {
      id
      slug
      name
      department
    }
    rating {
      count
      score
    }
    masterVariant {
      ...ProductVariantFields
      images {
        url
      }
      masterImage {
        url
      }
    }
    variants {
      ...ProductVariantFields
    }
    hasAugmentedReality
    productPills {
      displayLabel
      displayVariant
    }
    primaryProductPill {
      displayLabel
      displayVariant
    }
    isSponsored
  }
`
