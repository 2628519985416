import { BasketTotalItemsQuery } from '@moonpig/web-shared-utils'
import { MutationUpdaterFn } from '@apollo/client'
import { AddToBasketFind } from '../../queries/addToBasket'

export const useUpdateBasketTotalItemsQueryInCache = () => {
  const updateBasketTotalItemsQueryInCache: MutationUpdaterFn<
    AddToBasketFind
  > = (cache, { data }) => {
    if (data?.addToBasket?.__typename === 'Basket') {
      cache.writeQuery({
        query: BasketTotalItemsQuery,
        data: {
          me: {
            __typename: 'Me',
            basket: data.addToBasket,
          },
        },
      })
    }
  }

  return { updateBasketTotalItemsQueryInCache }
}
