import React, { FC } from 'react'
import { Box, Strip } from '@moonpig/launchpad-components'

const Banner = ({ text }: { text: string }) => (
  <Box data-testid="offer-banner">
    <Strip>{text}</Strip>
  </Box>
)

export const OfferBanner: FC<{ offerText?: string }> = ({ offerText }) => {
  return offerText ? <Banner text={offerText} /> : null
}
