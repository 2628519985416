import React from 'react'
import {
  Modal,
  Box,
  Heading,
  Text,
  PrimaryButton,
  Alert,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { useStoreId } from '@moonpig/web-core-stores'
import { useOnAddToBasket } from './useOnAddToBasket'
import { useLocaleText } from './RecommendationsCarousel.locale'
import { ProductTileProduct, ProductTile } from '../ProductTile'
import { AddedToBasketEvent, ProductList } from '../../types'
import { useFavouritesState } from '../../contexts/favourites'
import { useHandleFavourite } from '../../utils/favourites'

const ALERT_MARGIN = 4

const StyledAlert = styled(Alert)`
  position: absolute;
  z-index: 1;
  opacity: 0.9;
  pointer-events: none;
  ${s({
    margin: ALERT_MARGIN,
  })}
  width: ${({ theme }) => `calc(100% - ${theme.spacing[ALERT_MARGIN] * 2}px)`};
`

export const ProductTileWithBasketHandler: React.FC<{
  product: ProductTileProduct
  productsLength: number
  index: number
  pageName: string
  productList: ProductList
  showProductTabs?: boolean
  hideStickyCta?: boolean
  showOurPick?: boolean
  addToBasketSuccessCallback?: (e: AddedToBasketEvent) => void
}> = ({
  product,
  productsLength,
  index,
  pageName,
  productList,
  showProductTabs,
  hideStickyCta,
  showOurPick,
  addToBasketSuccessCallback,
}) => {
  const storeId = useStoreId()
  const localiseText = useLocaleText()
  const { onAddToBasket, isError } = useOnAddToBasket({
    product,
    index,
    productsLength,
    pageName,
    productList,
    componentName: 'carousel product tile',
  })
  const [showNotification, setShowNotification] = React.useState(false)
  const trackingData = {
    pageType: pageName.split('|')[0],
    productList,
    region: storeId,
    tile: {
      totalNumberOfProducts: productsLength,
      productIndex: index,
    },
  }

  const { favourites } = useFavouritesState()
  const handleFavourite = useHandleFavourite({
    tracking: {
      totalCount: 1,
      pageLocation: pageName,
    },
    removeWithConfirmation: false,
  })

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      zIndex={0}
      height="100%"
    >
      {showNotification && !isError && (
        <StyledAlert variant="success">
          <Text as="span" typography="typeBodyCaption">
            {localiseText('recommendations.added_to_basket')}
          </Text>
        </StyledAlert>
      )}

      <ProductTile
        product={product}
        trackingData={trackingData}
        onClick={() => {}}
        onAddToBasket={async e => {
          await onAddToBasket({
            productId: e.product.id,
            isProductCustomisable: e.product.customisable,
            variant: e.variant,
            quantity: e.quantity,
            selectedAddon: e.selectedAddon ?? null,
          })
          if (addToBasketSuccessCallback) {
            addToBasketSuccessCallback(e)
          } else {
            setShowNotification(true)
          }
        }}
        showProductTabs={showProductTabs}
        hideStickyCta={hideStickyCta}
        showOurPick={showOurPick}
        handleFavourite={handleFavourite}
        isFavourited={Boolean(
          favourites.find(/* istanbul ignore next */ p => p?.id === product.id),
        )}
      />
      <Modal
        label={localiseText('recommendations.error_modal.label')}
        isOpen={isError}
        onDismiss={() => {
          window.location.reload()
        }}
      >
        <Box textAlign="center">
          <Heading
            level="h2"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {localiseText('recommendations.error_modal.heading')}
          </Heading>

          <Text as="p">{localiseText('recommendations.error_modal.text')}</Text>
          <PrimaryButton
            onClick={() => {
              window.location.reload()
            }}
          >
            {localiseText('recommendations.error_modal.continue')}
          </PrimaryButton>
        </Box>
      </Modal>
    </Box>
  )
}
