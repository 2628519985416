import { useState } from 'react'
import { useMutation } from '@moonpig/web-core-graphql'
import { sanitizeStrings, trackGAEvent } from '@moonpig/web-core-analytics'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { useStore } from '@moonpig/web-core-stores'
import { useRouter } from '@moonpig/web-core-routing'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import { useCustomerId } from '@moonpig/web-shared-utils'
import {
  createAddToBasketGAEvent,
  createPersonaliseGAEvent,
} from '../../analytics'
import { useUpdateBasketTotalItemsQueryInCache } from './updateBasketTotalItemsQueryInCache'
import { Variant } from '../../types'
import {
  AddToBasketFind,
  AddToBasketGQL,
  AddToBasketAndSplitFind,
  AddToBasketAndSplitFindVariables,
  AddToBasketAndSplitGQL,
} from '../../queries/addToBasket'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'
import { ProductTileProduct } from '../ProductTile'
import { useAddDigitalGiftWithCardToBasket } from '../../utils/addDigitalGiftToBasket/useAddDigitalGiftWithCardToBasket'
import { MasterVariant } from '../../utils/addDigitalGiftToBasket/types'
import { isCardProduct } from '../../utils/isCardProduct'
import { isDigitalGift } from '../../utils/isDigitalGift/isDigitalGift'

export const useOnAddToBasket = ({
  product,
  index,
  productsLength,
  pageName,
  componentName = '',
  productList,
  isPersonalisedModal,
}: {
  product: ProductTileProduct
  index: number
  productsLength: number
  pageName: string
  componentName?: string
  productList?: {
    title: string
    position: string | number
    totalNumberOfLists: string | number
  }
  isPersonalisedModal?: boolean
}) => {
  const [isError, setIsError] = useState(false)
  const { updateBasketTotalItemsQueryInCache } =
    useUpdateBasketTotalItemsQueryInCache()

  const { addDigitalGiftWithCardToBasket } = useAddDigitalGiftWithCardToBasket()
  const customerId = useCustomerId()

  const { store } = useStore()
  const router = useRouter()

  const [addToBasketMutation] = useMutation<AddToBasketFind>(AddToBasketGQL, {
    update: updateBasketTotalItemsQueryInCache,
  })

  const [addToBasketAndSplit] = useMutation<
    AddToBasketAndSplitFind,
    AddToBasketAndSplitFindVariables
  >(AddToBasketAndSplitGQL)

  const onAddToBasket = async ({
    variant,
    quantity,
    selectedAddon,
    productId,
    isProductCustomisable,
  }: {
    variant: Variant
    quantity: number
    selectedAddon: AddonsFieldsFragment | null
    productId: string
    isProductCustomisable: boolean
  }) => {
    if (isDigitalGift(product.category.department)) {
      await addDigitalGiftWithCardToBasket({
        productId: product.id,
        digitalGiftProductMasterVariant: product.masterVariant as MasterVariant,
        region: store.id,
        customerId,
      })

      return
    }

    if (isProductCustomisable) {
      trackGAEvent(
        createPersonaliseGAEvent({
          componentName,
          productData: {
            id: product.id,
            name: product.title,
            price: product.masterVariant.price.centAmount,
            quantity: 1,
            category: product.category.name,
            variant: variant.title,
          },
        }),
      )
      router.push({
        name: 'customise',
        params: {
          region: store.id,
          id: productId,
          quantity,
          productVariantSku: variant.sku || undefined,
          variantKey: variant.key,
          addonSku: selectedAddon?.sku,
        },
      })
      return
    }

    const variables = {
      input: {
        sku: variant.sku as string,
        quantity,
        addons: selectedAddon?.sku ? [{ sku: selectedAddon.sku }] : [],
      },
    }

    try {
      const { data } =
        !isCardProduct(product.category.department) && quantity > 1
          ? await addToBasketAndSplit({ variables })
          : await addToBasketMutation({
              variables,
            })

      const errors = ['ProductNotFoundError', 'ConcurrentModificationError']

      if (errors.includes(data?.addToBasket?.__typename as string)) {
        trackGAEvent({
          event: 'server_error',
          ecommerce: undefined,
          event_data: {
            category: 'server error',
            action: data?.addToBasket?.__typename,
            label: (data?.addToBasket as { message: string })?.message,
            non_interaction: true,
            value: undefined,
          },
          error_data: {
            id: data?.addToBasket?.__typename,
            message: (data?.addToBasket as { message: string })?.message,
          },
        })
        setIsError(true)
      }

      if (data?.addToBasket?.__typename === 'Basket') {
        const basket = {
          id: data.addToBasket.id,
          size: data.addToBasket.totalItems,
          action: 'add to cart',
        }
        const currency = variant.price.currencyCode
        const carouselLabel = productList
          ? `${productList.title} | carousel ${
              isPersonalisedModal ? 'personalised promo modal ' : ''
            }| ${productList.position}/${productList.totalNumberOfLists} | ${
              index + 1
            }/${productsLength}`
          : `${index + 1}/${productsLength}`

        const itemLabel = `${pageName} | ${carouselLabel}`
        const addonLabel = selectedAddon?.sku
          ? ` + addon: ${selectedAddon.sku}`
          : ''
        const label = sanitizeStrings(
          `${itemLabel} | ${product.id}${addonLabel}`,
        )

        const brand = BRAND_LABEL.toLowerCase()

        const productCentAmount = product.masterVariant.price.centAmount
        const addonProductCentAmount = selectedAddon?.price?.centAmount ?? 0
        const totalValue = Number(
          ((productCentAmount + addonProductCentAmount) / 100).toFixed(2),
        )

        const products = []
        const item = {
          index: 1,
          item_brand: brand,
          item_name: product.title.toLowerCase(),
          item_category: product.category.name,
          item_category2: getParentDepartment(product.category.department),
          item_category3: 'in-stock',
          item_category4: 'non-group-card',
          item_category5: undefined,
          item_id: product.id.toLowerCase(),
          item_list_name: `${itemLabel} | ${product.id}`,
          item_variant: product.masterVariant.title.toLowerCase(),
          quantity,
          price: productCentAmount / 100,
        }
        products.push(item)

        if (selectedAddon) {
          const addonItem = {
            item_id: selectedAddon.sku.toLowerCase(),
            item_name: selectedAddon.title.toLowerCase(),
            item_brand: brand,
            item_variant: 'add on',
            item_category3: 'in-stock',
            item_category4: 'non-group-card',
            item_category5: 'non-favourite',
            item_list_name: `${itemLabel} | ${selectedAddon.sku}`,
            price: selectedAddon.price.centAmount / 100,
            quantity: 1,
          }
          products.push(addonItem)
        }

        trackGAEvent(
          createAddToBasketGAEvent({
            basket,
            currency,
            products,
            label,
            totalValue,
          }),
        )
      }
    } catch {
      setIsError(true)
    }
  }

  return {
    onAddToBasket,
    isError,
  }
}
